/* Base styles */
.sidebar {
  width: 250px;
  transition: transform 0.3s ease;
}

/* Desktop view */
@media (min-width: 992px) {
  .sidebar {
    position: static;
    transform: none;
  }

  .sidebar-toggle {
    display: none;
  }
}

/* Mobile view */
@media (max-width: 991px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #fff;
    transform: translateX(-100%);
    z-index: 1000;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .sidebar-toggle {
    width: 40px;
    height: 50px;
  }
}
.ps-6 {
  padding-left: 5rem !important;
}
.ps-7 {
  padding-left: 7rem !important;
}
