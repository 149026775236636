/* Common Form Styles */
.form-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .section {
    margin-bottom: 2rem;
    padding: 1.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
  }
  
  .section-title1 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
  
  .input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    font-size: 0.875rem;
  }
  
  .text-area {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    resize: vertical;
  }
  
  .media-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .media-preview {
    max-width: 300px;
    max-height: 300px;
    object-fit: contain;
  }
  
  .video-preview {
    max-width: 300px;
    max-height: 300px;
  }
  
  .file-input-wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .file-input {
    display: none;
  }
  
  .file-input-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: #f3f4f6;
    border-radius: 0.25rem;
    cursor: pointer;
  }
  
  .file-input-label:hover {
    background-color: #e5e7eb;
  }
  
  .update-button {
    background-color: #3b82f6;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border: none;
    font-weight: 500;
    width: 100%;
  }
  
  .update-button:disabled {
    background-color: #9ca3af;
    cursor: not-allowed;
  }
  
  .update-button:hover:not(:disabled) {
    background-color: #2563eb;
  }
  
  .remove-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    color: #ef4444;
    border-radius: 0.25rem;
    border: none;
    background: transparent;
  }
  
  .remove-button:hover {
    background-color: #fee2e2;
  }
  
  .error-text {
    color: #ef4444;
    font-size: 0.875rem;
    margin-top: 0.5rem;
  }
  
  /* Navbar Management Form Specific Styles */
  .logo-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .logo-preview {
    max-width: 100px;
    max-height: 100px;
    object-fit: contain;
  }
  
  .menu-item-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr auto;
    gap: 1rem;
    align-items: start;
    margin-bottom: 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
  }
  
  .add-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
    background-color: #f3f4f6;
    border: 1px dashed #e2e8f0;
    border-radius: 0.25rem;
    color: #4b5563;
  }
  
  .add-button:hover {
    background-color: #e5e7eb;
  }
  
  /* Testimonial Management Form Specific Styles */
  .button-group {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  .action-button {
    background-color: #3b82f6;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border: none;
    font-weight: 500;
    flex: 1;
  }
  
  .action-button:disabled {
    background-color: #9ca3af;
    cursor: not-allowed;
  }
  
  .action-button:hover:not(:disabled) {
    background-color: #2563eb;
  }
  
  .action-button.secondary {
    background-color: #f3f4f6;
    color: #374151;
  }
  
  .action-button.secondary:hover:not(:disabled) {
    background-color: #e5e7eb;
  }
  
  .action-button.danger {
    background-color: #ef4444;
  }
  
  .action-button.danger:hover:not(:disabled) {
    background-color: #dc2626;
  }
  
  .action-button.small {
    padding: 0.25rem 0.75rem;
    flex: 0 0 auto;
  }
  
  .testimonials-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
  }
  
  .testimonial-card {
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .testimonial-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .testimonial-photo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .testimonial-info {
    display: flex;
    flex-direction: column;
  }
  
  .testimonial-name {
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
  }
  
  .testimonial-place {
    font-size: 0.875rem;
    color: #6b7280;
    margin: 0.25rem 0 0;
  }
  
  .testimonial-content {
    font-size: 0.875rem;
    margin-bottom: 1rem;
  }
  
  .testimonial-content p {
    margin: 0 0 0.5rem;
  }
  
  .testimonial-actions {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
  }
  
  /* Bulk Upload Form Specific Styles */
  .file-list-container {
    margin-top: 1rem;
  }
  
  .file-item {
    display: grid;
    grid-template-columns: 1fr auto auto;
    gap: 1rem;
    align-items: center;
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;
  }
  
  .file-name {
    font-size: 0.875rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .file-size {
    font-size: 0.75rem;
    color: #6b7280;
  }
  
  