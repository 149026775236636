/* Ensure the pop-up is initially hidden */
.popup {
    display: none;
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 1000;
  }
  
  /* Show the pop-up when the parent link is hovered */
  .jobseeker-hover:hover .popup {
    display: block;
  }
  