.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
}

.card-wrapper {
  flex: 1 1 calc(50% - 1rem);
  max-width: calc(50% - 1rem);
  margin-bottom: 1rem;
}

.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
}

@media (max-width: 768px) {
  .card-wrapper {
    flex: 1 1 100%;
    max-width: 100%;
  }
  /* .job-bx {
    overflow-x: hidden !important;
  } */
}

@media (max-width: 576px) {
  .card-wrapper {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
@media only screen and (max-width: 540px) and (min-width: 450px) {
  .card {
    width: 452px !important;
  }
}
@media only screen and (max-width: 440px) and (min-width: 320px) {
  .card {
    width: 278px !important;
  }
}
