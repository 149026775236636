/* FixedHeader.css */
.overlay-black-dark {
    padding-top: 50px;
    padding-bottom: 20px;
    background-size: cover;
    background-position: center;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .profile-edit {
    display: flex;
    justify-content: space-around;
  }
  
  .candidate-info {
    width: 100%;
  }
  
  .candidate-detail {
    display: flex;
    justify-content: center;
  }
  
  .canditate-des img {
    height: 100px;
  }
  
  .progress-bx {
    margin-bottom: 20px;
  }
  
  .progress {
    background-color: #f3f3f3;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .progress-bar {
    height: 10px;
    background-color: #1c2957;
    transition: width 0.3s ease-in-out;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .profile-edit {
      flex-direction: column;
      align-items: center;
    }
  
    .candidate-detail {
      flex-direction: column;
      align-items: center;
    }
  
    .ms-5 {
      margin-top: 20px;
      margin-left: 0;
    }
  
    .form-group {
      width: 100%;
      text-align: center;
    }
  
    .site-button {
      width: 100%;
      margin-top: 10px;
    }
  }
  